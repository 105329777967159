import "./profile.scss";
import {connect}  from 'react-redux'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Details(props) {
  const { t } = useTranslation()
  const phone = props.authedUser?.phone;
  let formatPhone = "(" + (phone?.slice(0,3)) + ") " + (phone?.slice(3, 6)) + " - " + (phone?.slice(6, 10));
  return (
    <div className="details-container">
      <div>
        <div className="details-label">{t("profile.country")}</div>
        <div>{props.authedUser?.country}</div>
      </div>
      <div>
        <div className="details-label">{t("profile.phone")}</div>
        <div>{formatPhone}</div>
      </div>
      <div>
        <div className="details-label">{t("profile.language")}</div>
        <div>{props.authedUser?.language}</div>
      </div>
    </div>
  );
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}
export default connect(mapStateToProps)(Details)
