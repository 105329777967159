import "./profile.scss";
import Button from "../common/Button"
import * as React from "react";
import {connect}  from 'react-redux'
import { useTranslation} from 'react-i18next'
import LockSyncIcon from "../../icons/profile/lock_sync.svg"
import TextInputField from '../common/TextInputField'
import Auth from "@aws-amplify/auth";
import { useFormFields } from "../../libs/hooks";
import { getUserValidationShema, validateForm } from '../../utils/formValidation'
import {
    ArrowDownIcon,
    CaretUpIcon
} from "../../icons"

function EditPassword(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const [error, setError] = React.useState({})
  const [isSubmitting, setSubmitting] = React.useState(false)
  const [fields, handleFieldChange] = useFormFields({
    email: props.authedUser?.email,
    password: "",
    newpassword: "",
    confirmpassword: "",
  });

  const userErrorMsg = {
    email: t("error.email"),
    password: t("error.password"),
    confirmpassword: t("error.confirm_password")
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    setError({})
    setSubmitting(true)

    const userSchema = getUserValidationShema(userErrorMsg)
    const validateData = await validateForm(userSchema, fields)
    setError(validateData)

    Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, fields.password, fields.newpassword);
    })
    .then(data => console.log(data))
    .catch(err => console.log(err));

  }

  return (
    <div className="edit-password-container">
      <div className="edit-profile-details"
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <span className="profile-span"><img src={LockSyncIcon} alt=""/></span>
        <div className="name-icon-profile">
          <span className="profile-span">{t("profile.edit_password")}</span>
          {isOpen ? <CaretUpIcon className="arrow-down-profile" /> : <ArrowDownIcon className="arrow-down-profile" />}
        </div>
      </div>

      {isOpen && (
        <div>
          <TextInputField 
          placeholder={t("profile.email")} 
          autoFocus
          type="text"
          name="email" 
          value={fields.email} 
          onChange={handleFieldChange} 
          error= {error.email ? error.email : null}
          isRequired={true}
          />

          <TextInputField 
          placeholder={t("profile.password")}
          autoFocus
          type="password"
          name="password" 
          error= {error.password ? error.password : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          value={fields.password} 
          onChange={handleFieldChange}
         />

          <TextInputField 
          placeholder={t("profile.new_password")} 
          autoFocus
          type="password"
          name="newpassword" 
          error= {error.password ? error.password : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          value={fields.newpassword} 
          onChange={handleFieldChange} />

          <TextInputField  
          placeholder={t("profile.confirm_new_password")}
          autoFocus
          type="password"
          name="confirmpassword" 
          error= {error.password ? error.password : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          value={fields.confirmpassword} 
          onChange={handleFieldChange} />

          <Button onClick={handleUpdate} className="small">{t("labels.save")}</Button>
        </div>
      )}
    </div>
  );
}
function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}
export default connect(mapStateToProps)(EditPassword)