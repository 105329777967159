import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { handleSetAuthedUser } from '../../actions/authedUser'
import { Link } from 'react-router-dom'
import { Auth } from "aws-amplify"
import './login.scss'
import { useAppContext } from "../../libs/contextLib"
import Button from '../common/Button'
import PublicPageHeading from "../common/PublicPageHeading"
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { useTranslation } from 'react-i18next'
import { getEmailValidationShema, validateForm } from '../../utils/formValidation'
import { toast } from 'react-toastify';
import {
  EmailIcon,
  LockIcon,
  EyeOffIcon,
  EyeIcon
} from '../../icons'

function Login(props) {
  const [isLoading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [pError, setAuthenticationError] = useState('')
  const { setAuthenticated } = useAppContext()
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    remember: localStorage.remember_me !== null ? true : false
  })
  const { t } = useTranslation()
  const userErrorMsg = {
    email: t("error.login_error")
  }

  useEffect(() => {
    const controller = new AbortController()
    return () => {
      setLoading(false)
      controller.abort()
    }
  }, [])

  //set email value from storage and trigger change event
  useEffect(() => {
      const email = localStorage.remember_me
        
      if(email != null ){
        const emailElem = document.getElementById('email')
        const checkboxElem = document.getElementById('remember-me')

        const event = new Event('input', { 'bubbles': true })
        const ev = new Event('change', { 'bubbles': true })
        
        const setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
        setValue.call(emailElem, email)


        const setChecked = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked').set
        setChecked.call(checkboxElem, true)
        
        document.getElementById('password').value = ''

        emailElem.dispatchEvent(event)
        checkboxElem.dispatchEvent(ev)
      }
  }, [])


  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    toast.dismiss()

    const { dispatch} = props
    try {
      document.getElementById('remember-me').checked = false

      const loginSchema = getEmailValidationShema(userErrorMsg)
      const validateData = await validateForm(loginSchema, fields)
      
      setAuthenticationError(validateData.email)

      if(Object.keys(validateData).length > 0) {
        setLoading(false)
        return false
      }

      Auth.signIn(fields.email, fields.password)
      .then( sess => {
        const userAttr = sess.attributes

        dispatch(
          handleSetAuthedUser({
            email: userAttr['email'],
            encryptedEmail: userAttr['email'] ? btoa(userAttr['email']) : '',
            firstname: userAttr['given_name'] || '',
            lastname: userAttr['family_name'] || '',
            language: userAttr['locale'] || '',
            company: userAttr['custom:company'] || '',
            companywebsite:userAttr['website'] || '',
            phone: userAttr['custom:phone_number'] || '',
            fax: userAttr['custom:fax'] || '',
            city: userAttr['custom:city'] || '',
            stateprovince: userAttr['custom:state'] || '',
            country: userAttr['custom:country'] || '',
            postalcode: userAttr['custom:postal_code'] || '',
            userGroup: userAttr['custom:user_group'] || 'coltene_customer_service',
            privacyAccepted: userAttr['custom:privacy'] || '',
            cognitoUser: sess['username']
          })
        )
      })
      .then( () => {
        setAuthenticated(true)
        setLoading(false)
        handleRememberMe()
      })
      .catch ((e) => {
        setLoading(false)
        processError(e)
      })
    } catch (e) {
      const em = e.message
      setAuthenticationError(em)
      setLoading(false)
    }
  }

  const validateLoginForm = () => {
    return fields.email.length > 0 && fields.password.length > 0
  }

  const handleRememberMe = () => {
    if(!storageAvailable) {
      return false
    }

    if(fields.remember && fields.remember !== null) {
      localStorage.setItem("remember_me", fields.email)
    } else {
      localStorage.removeItem("remember_me")
    }
  }

  const storageAvailable = () => {
    try {
      localStorage.setItem('t', 'test')
      localStorage.removeItem('t')
      return true
    } catch (e) {
      console.log('Storage not available', e.message)
      return false
    }
  }

  const toggleShowHidePassword = (e) => {
    document.getElementById('password').type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }


  return (
    <div className="center login-container public-page">
      <div className="center form-container">
        <PublicPageHeading heading={t('access.login_in_to_account')} />
        <div className="login-form">
          <form onSubmit={handleLogin} noValidate>
            { pError &&
              <p className="error-message">{pError}</p>
            }
            <div className="input-fields">
              <div className="input-box"> 
                <EmailIcon className="at-icon" />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={fields.email}
                  autoFocus
                  placeholder={t('profile.email')}
                  onChange = {handleFieldChange}
                  autoComplete="off"
                />
              </div>
              <div className="input-box">
                <LockIcon className="lock-icon" />
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={fields.password}
                  placeholder={t('profile.password')}
                  onChange = {handleFieldChange}
                  autoComplete="off"
                />
                { hidePassword
                  ? <EyeIcon className="eye-icon" onClick={toggleShowHidePassword} />
                  : <EyeOffIcon className="eye-icon" onClick={toggleShowHidePassword} />
                }
              </div>
            </div>


            <div className="remember-wrapper">
              <div className="remember-me">
                <input
                type="checkbox"
                id="remember-me"
                name="remember"
                className="checkbox"
                onClick = {handleFieldChange}
                />
                <label htmlFor="remember-me">{t('access.remember_me')}</label>
              </div>
              <div>
                <Link to="/forgot-password" className="forgot-password">
                {t('access.forgot_password')}
                </Link>
              </div>
            </div>
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!validateLoginForm()}
              className="large"
            >
              {t('access.login')}
            </Button>
          </form>
          
        </div>
        <div className="signup-link">
          {t('access.dont_have_account')} &nbsp;
          <Link to="register/create-account/" className="sign-up"> 
            {t('access.register_account')} 
          </Link>
        </div>
      </div>
    </div>
  )
}

// function mapStateToProps({authedUser}) {
//   return {
//       authedUser
//   }
// }

export default connect()(Login)