import React, {useEffect, useRef, useState} from 'react'

import "./click-outside.scss"
import PropTypes from "prop-types";

const ClickOutside = (props) => {
  const {itemRef} = props
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const ref = useRef(itemRef);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleVisibility(false)
    }
  }

  const toggleVisibility = (value) => {
    setIsComponentVisible(value)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return (
    <div className="click-outside-wrapper cursor-pointer" ref={ref}>
      {isComponentVisible && (
        <div>
          {props.eventItem}
          {props.toDisplayItem({ toggleVisibility })}
        </div>
      )}
      {!isComponentVisible && (
        <div onClick={() => toggleVisibility(true)}>
          {props.eventItem}
        </div>
      )}
    </div>
  )
}

ClickOutside.propTypes = {
  itemRef: PropTypes.string.isRequired,
  eventItem: PropTypes.object.isRequired,
  toDisplayItem: PropTypes.func.isRequired
}

export default ClickOutside
