import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useAppContext } from "../../../libs/contextLib"
import { useTranslation } from 'react-i18next'
import './nav.scss'
import Language from '../Language'
import Profile from "../../Profile";
import useOnClickOutside from "../Banner/useOnClickOutside"
import {
    CaretUpIcon,
    HomeIcon,
    ArrowDownIcon
} from '../../../icons'

const Nav = (props) => {
    const {isAuthenticated} = useAppContext()
    const { t } = useTranslation()
    const [hideSideBar, setHideSideBar] = React.useState(false)
    const [showSubMenu, toggleShowSubMenu] = React.useState(false)

    const fi = props.authedUser?.firstname.charAt(0)
    const li = props.authedUser?.lastname.charAt(0)

    const ref = React.useRef(null)

    const handleSideBar = () => {
        setHideSideBar((prevState) => !prevState)
    };

    const handleClickOutside = (e) => {
        setHideSideBar((prevState) => !prevState)
    };

    const handleClickInside = () => {
        setHideSideBar(false)
    };

    useOnClickOutside(ref, handleClickOutside)

    const closeMenu = () => {
        props.closeNavDisplay(false)
        closeSubMenus()
    }

    const closeSubMenus = () => {
        document.querySelectorAll('.nav-product-wrapper').forEach(function(elem) {
            removeClass(elem, 'current')
        })
    }

    const removeClass = (elem, cls) => {

        const classes = elem.className.split(" ")
        const i = classes.indexOf(cls)

        if (i >= 0){
            classes.splice(i, 1)
            elem.className = classes.join(" ")
        }
    }

    const handleExpandNav = (e) => {
        e.preventDefault()

        toggleShowSubMenu(!showSubMenu)
    }

    return (
        isAuthenticated &&
        <div id="main-menu" className={"menu-wrapper" + (props.expandNavMenu ? " nav-expand" : "")}>
            <div className="menu-container">
                {/* <div className="menu-search-container menu-item">
                    <div>
                        <Search
                            placeholder="What are you searching for?"
                            onSearch={handleSearch}
                        />
                    </div>
                </div> */}
                <div className="menu-button-wrapper  menu-item">
                    <hr className="hr" />
                    <div className="banner-profile-wrapper profile-element">
                        <div onClick = {handleSideBar} className="handle-open profile-element">
                            <div className="avatar-title profile-element" >
                                {/* <img className="avatar-image-small" src={Image} alt="" /> */}
                                <div className="avatar-initials-small profile-element">
                                    <span profile-element>{fi}{li}</span>
                                </div>
                                <div>
                                    <span className="profile-name profile-element">{props.authedUser?.firstname} {props.authedUser?.lastname}</span>
                                </div>
                            </div>
                            <div className="profile-element">
                                {hideSideBar ? <ArrowDownIcon /> : <CaretUpIcon />}
                            </div>
                        </div>
                        {!hideSideBar && (
                            <div ref={ref} onClick={handleClickInside}>
                                <Profile/>
                            </div>
                        )}
                    </div>
                    <hr className="hr"/>
                </div>
                <div className="menu-language-wrapper  menu-item">
                    <Language/>
                </div>
                <nav className='nav'>
                    <ul>
                        <li>
                            <NavLink to='/' exact activeClassName='active' onClick={closeMenu}>
                                <div title={t("nav.home")}><HomeIcon className="icon" /></div> {t("nav.dashboard")}
                            </NavLink>
                        </li>
                    </ul>

                </nav>
            </div>
        </div>
    )
}

function mapStateToProps ({ authedUser, userProducts }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(Nav)
