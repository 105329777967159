import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createStore} from 'redux'
import { Provider } from 'react-redux'
import reducer from './reducers'
import middleware   from './middleware'
import './index.scss'
import App from '../src/components'
import { BrowserRouter as Router } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import config from './utils/config'
import {ToastContainer, Slide} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './libs/i18n'
import LoadingSpinner from './components/common/LoadingSpinner'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "colteneApiGateway",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

const store = createStore(reducer, middleware)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner />}>
      <Provider store={store} >
        <ToastContainer limit={1}
          position="top-center"
          transition={Slide}
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
