import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { Auth } from "aws-amplify"
import { handleSetAuthedUser } from '../actions/authedUser'
import Banner from './common/Banner'
import Nav from './common/Nav'
import Footer from './common/Footer'
import Routes from './common/Routes'
import { AppContext } from "../libs/contextLib";
import { processError } from "../libs/processError"
import { useTranslation } from 'react-i18next'
import { toast } from "react-toastify"
import './App.scss'
import {
    ColteneLogoBlack
} from '../images'
import config from '../utils/config'
import LoadingSpinner from './common/LoadingSpinner'

function App(props) {
  const [isAuthenticated, setAuthenticated] = useState(!config.cognito.USER_POOL_ID)
  const [isAuthenticating, setAuthenticating] = useState(true)
  const [expandNavMenu, setExpandNavMenu] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const controller = new AbortController()
    // onLoad()
    const onLoad = () => {
      const { dispatch } = props

      try {
        Auth.currentSession()
          .then(async res => {
            setAuthenticated(true)

            const credentials = await Auth.currentCredentials();
            const { identityId } = credentials;

            const userAttr = res.getIdToken().payload
            const encryptedEmail = btoa(userAttr['email'])

            dispatch(
              handleSetAuthedUser({
                email: userAttr['email'],
                encryptedEmail: userAttr['email'] ? encryptedEmail : '',
                firstname: userAttr['given_name'] || '',
                lastname: userAttr['family_name'] || '',
                language: userAttr['locale'] || '',
                company: userAttr['custom:company'] || '',
                companywebsite:userAttr['website'] || '',
                phone: userAttr['custom:phone_number'] || '',
                fax: userAttr['custom:fax'] || '',
                city: userAttr['custom:city'] || '',
                stateprovince: userAttr['custom:state'] || '',
                country: userAttr['custom:country'] || '',
                postalcode: userAttr['custom:postal_code']  || '',
                userGroup: userAttr['custom:user_group'] || 'coltene_customer_service',
                privacyAccepted: userAttr['custom:privacy'] || '',
                cognitoUser:  res.getAccessToken().payload.username,
                identity: identityId

              }))

            return {
              encryptedEmail: encryptedEmail
            }

          })
          .then( (encryptedEmail) => {
            setAuthenticating(false)
          })
          .catch((e) => {
            if (e && e !== 'No current user') {
              console.error(e)
            }
            processError(e)
            setAuthenticating(false)
          })
      }
      catch(e) {
        if (e !== 'No current user') {
          console.info(e)
        }
        processError(e)
        setAuthenticating(false)
      }
    }

    const onSessionInactive = () => {
      const { dispatch } = props
      Auth.signOut()
        .then(() => setAuthenticated(false))
        .then(() => dispatch(handleSetAuthedUser(null)))
    }

    onLoad()

    const tout = 8*1000*60*60
    let exp

    if(isAuthenticated) {
      exp = setTimeout(() => onSessionInactive(), tout)
    }

    return () => {
      controller.abort()

      if(exp) {
        clearTimeout(exp)
      }
    }
  }, [t,props,isAuthenticated])

  useEffect(() => {
    toast.dismiss()
  })

  const toggleNavDisplay = (val) => {
    setExpandNavMenu(!expandNavMenu)
  }

  const closeNavDisplay = () => {
    setExpandNavMenu(false)
  }

  return (
    !isAuthenticating ? (
      <div id="ms-dashboard" className="App container">
        <AppContext.Provider value={{ isAuthenticated, setAuthenticated }} >
          <Banner toggleNavDisplay={toggleNavDisplay} />
          <div id="page-content">
            {!isAuthenticated &&
              <div className="logo-wrapper center">
                <img
                  src={ColteneLogoBlack}
                  alt='SciCan Logo'
                />
              </div>
            }
            <Nav expandNavMenu={expandNavMenu} closeNavDisplay={closeNavDisplay} />
            <div id="main"  className={(expandNavMenu ? " nav-expand" : "")}>
              <Routes />
            </div>

          </div>
        </AppContext.Provider>
        <Footer />
      </div>
    )
    : isAuthenticating &&
      <LoadingSpinner />
  )
}

export default withRouter(connect()(App))
