import "./profile.scss";
import {connect} from "react-redux";
import Button from "../common/Button"
import * as React from "react";
import OfficeInformationIcon from "../../icons/profile/office_information.svg"
import Auth from "@aws-amplify/auth";
import { useFormFields } from "../../libs/hooks";
import { useTranslation } from 'react-i18next'
import TextInputField from '../common/TextInputField'
import {
  CaretUpIcon,
  ArrowDownIcon,
}from "../../icons"

function OfficeInformation(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [fields, handleFieldChange] = useFormFields({
    company:props.authedUser?.company,
    phone:props.authedUser?.phone,
    website:props.authedUser?.companywebsite,
  });
  const { t } = useTranslation()
  const handleUpdate = async (e) => {
    let user = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(user, {
      'custom:company': fields.company || '',
      'custom:company_phone': fields.phone || '',
      'website': fields.companywebsite || ' '
    });
  }

  return (
    <div className="office-info-container">
      <div className="edit-profile-details"
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <span className="profile-span"><img src={OfficeInformationIcon} alt="" className="" /></span>
        <div className="name-icon-profile">
          <span className="profile-span">{t("profile.office_information")}</span>
          {isOpen ? <CaretUpIcon className="arrow-down-profile" /> : <ArrowDownIcon className="arrow-down-profile" />}
        </div>
      </div>

      {isOpen && (
        <div className="edit-profile-form-container">
          <TextInputField
           className="profile-fields"
           name="company"
           value={fields.company}
           autoFocus
           onChange={handleFieldChange}
           placeholder={t("profile.company")}
           type="text"
          />
          <TextInputField
            className="profile-fields"
            name="phone"
            value={fields.phone}
            autoFocus
            onChange={handleFieldChange}
            placeholder={t("profile.phone")}
            type="text"
          />
          <TextInputField
            className="profile-fields"
            name="companywebsite"
            value={fields.website}
            autoFocus
            onChange={handleFieldChange}
            placeholder={t("profile.company_website")}
            type="text"
          />
          <Button onClick={handleUpdate} className="small">{t("labels.save")}</Button>
        </div>
      )}
    </div>
  );
}
function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}
export default connect(mapStateToProps)(OfficeInformation)
