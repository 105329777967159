import "./profile.scss";
import {connect}  from 'react-redux'
// import {CameraIcon,
// } from "../../icons"
import React from "react"

function Avatar(props) {
  const fi = props.authedUser?.firstname.charAt(0)
  const li = props.authedUser?.lastname.charAt(0)

  return (
    <div className="avatar-container">
      <label htmlFor="file-input">
        <div className="avatar-image-initials">
            <span>{fi}{li}</span>
        </div>
      </label>
      <div className="person-name">{props.authedUser?.firstname} {props.authedUser?.lastname}</div>
    </div>
  );
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}
export default connect(mapStateToProps)(Avatar)
