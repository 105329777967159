import React, {useEffect, useState} from 'react'
import {ArrowBackIcon} from "../../../icons";
import {daysOfWeek, months} from "../../../utils/filters/date";
import PropTypes from "prop-types";

function Calendar(props) {
  const {selectDay, value} = props
  const [selectedDate, setSelectedDate] = useState(value ? value : null)
  const localMonths = months()
  const localDaysOfWeek = daysOfWeek()
  const [date, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  useEffect(() => {

    if (selectedDate) {
      const initialDate = new Date(Date.parse(selectedDate))
      setDate({month: initialDate.getMonth(), year: initialDate.getFullYear()})
    }

  }, [props, selectedDate])

 const formatVal = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const datesForGrid = (year, month) => {
    const dates = []
    const firstDay = new Date(year, month).getDay()
    const lastDay = new Date(year, month + 1, 0).getDay()
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate()
    const totalDaysInPrevMonth = new Date(year, month, 0).getDate()

    let currentDate = selectedDate || `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth())}-${formatVal((new Date()).getDate())}`

    for (let i = 1; i <= firstDay; i++) {
      const prevMonthDate = totalDaysInPrevMonth - firstDay + i
      const key = `${date.year}-${formatVal(date.month)}-${formatVal(prevMonthDate)}`
      dates.push({key: key, date: prevMonthDate, monthClass: 'prev'})
    }

    for (let i = 1; i <= totalDaysInMonth; i++) {
      const key = `${date.year}-${formatVal(date.month +1 )}-${formatVal(i)}`
      let object = {key: key, date: i, monthClass: 'current'}
      if (key === currentDate) {
        object.selectedClass = 'selected'
      }

      if (i < 10) {
        object.date = `0${object.date}`
      }

      dates.push(object)
    }

    const diff = daysOfWeek().length - (lastDay + 1)

    if (dates.length < (dates.length + diff)) {
      const count = (dates.length + diff) - dates.length
      for (let i = 1; i <= count; i++) {
        const key = `${date.year}-${formatVal(date.month + 2)}-${formatVal(i)}`

        if (i < 10) {
          i = `0${i}`
        }

        dates.push({key: key, date: i, monthClass: 'next'})
      }
    }
    return dates
  }

  const prevMonth = (e) => {
    e.preventDefault()
    const localDate = new Date(date.year, date.month - 1)
    setDate({
      month: localDate.getMonth(),
      year: localDate.getFullYear(),
    })
  }

  const nextMonth = (e) => {
    e.preventDefault()
    const localDate = new Date(date.year, date.month + 1)
    setDate({
      month: localDate.getMonth(),
      year: localDate.getFullYear(),
    })
  }

  const handleSelected = (e) => {
    const value = e.target.id
    setSelectedDate(value)
    selectDay(value)
  }

  return (
    <div>
      <div className="calendar-header">
        <button onClick={(e)=>prevMonth(e)}>
          <ArrowBackIcon className="left"/>
        </button>
        <h2 className="month">{localMonths[date.month]} {date.year}</h2>
        <button onClick={(e)=>nextMonth(e)}>
          <ArrowBackIcon className="right"/>
        </button>
      </div>
      <div className='calendar-content'>
        {
          localDaysOfWeek.map((day, index) => (
            <div className="text-gray day-of-week" key={`day-${index}`}>{day}</div>
          ))
        }
        {
          datesForGrid(date.year, date.month).map((date, index) => (
            <div className="date" key={date.key}>
              <div
                id={date.key}
                onClick={handleSelected}
                className={`
                  ${date.monthClass} 
                  ${
                  date.selectedClass
                    ? `${date.selectedClass} cursor-pointer`
                    : 'cursor-pointer'
                }
                `}>
                {date.date}
              </div>
              <div className="dots d-flex">
                <div className={`dot ${date.dot ? date.dot : ''}`}/>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

Calendar.propTypes = {
  selectDay: PropTypes.func.isRequired,
  printouts: PropTypes.array,
  value: PropTypes.string
}

export default Calendar
