import React from "react"
import { connect } from "react-redux"
import "./dashboard.scss"
import { useAppContext } from "../../libs/contextLib"
import Login from "../Login";
import DashboardComponent from "./Dashboard"

function Dashboard(props) {
    const { isAuthenticated } = useAppContext()

    return !isAuthenticated ? (
        <Login />
        ) : (
        <div className="dashboard-wrapper">
            {
                <DashboardComponent/>
            }

        </div>
    );
}

function mapStateToProps({ authedUser, userProducts }) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(Dashboard);
