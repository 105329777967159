import React from 'react'
import './footer.scss'

function Footer(props) {
  return (
    <div></div>
  )
}


export default Footer
