import {useTranslation} from "react-i18next";
import "./calendar-input.scss"
import React, {useEffect, useRef, useState} from "react";
import {CalendarIcon} from "../../../icons";
import PropTypes from "prop-types";
import Calendar from "./Calendar";


const CalendarInput = (props) => {
  const {t} = useTranslation()

  const {onChangeFrom, onChangeTo, printouts, valueFrom, valueTo} = props
  const [showCalendar, setShowCalendar] = useState(false)
  const [rangeFrom, setRangeFrom] = useState(null)
  const [rangeTo, setRangeTo] = useState(null)

  const ref = useRef('calendar');

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowCalendar(false)
    }
  }

  useEffect(() => {
    if (showCalendar) {
      const options = document.getElementById('calendar-options')
      const input = document.getElementById('calendar-input')

      if (input && options) {
        const i = input.getBoundingClientRect()
        const o = options.getBoundingClientRect()

        if (document.documentElement.clientHeight < (i.height + i.top + o.height)) {
          options.style.top = `-${o.height}px`
        }
      }
    }

    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [props, showCalendar])

  const formatVal = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const selectDay = (data) => {
    let from = null
    let to = null
    if (data && data.type === 'from') {
      from = data.value
      setRangeFrom(from)
      onChangeFrom(from)
    }

    if (data && data.type === 'to') {
      to = data.value
      if (!rangeFrom) {
        from = `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth() + 1)}-${formatVal((new Date()).getDate())}`
        setRangeFrom(from)
        onChangeFrom(from)
      }
      setRangeTo(to)
      onChangeTo(to)
      toggleCalendar()
    }
  }

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar)
  }
  return (
    <div id="calendar-input" ref={ref} className="calendar-wrapper">
      <div className="calendar-input-wrapper">
        <div className="calendar-input" onClick={() => toggleCalendar()}>
          <div className="custom-selected">
            {
              rangeFrom || rangeTo ? (
                  `${rangeFrom ? rangeFrom : t('placeholders.date-from')} - ${rangeTo ? rangeTo : t('placeholders.date-to')}`
                )
                : t('placeholders.date-of-purchase')
            }
          </div>
          <CalendarIcon/>
        </div>
      </div>
      {
        showCalendar && (
          <div id="calendar-options" className="calendar-range">
            <div className="calendar">
              <div className="date-title">
                {t('from')}
              </div>
              <Calendar selectDay={(value) => selectDay({type: 'from', value: value})} printouts={printouts}
                        value={valueFrom || rangeFrom}/>
            </div>
            <div className="calendar">
              <div className="date-title">
                {t('to')}
              </div>
              <Calendar selectDay={(value) => selectDay({type: 'to', value: value})} printouts={printouts}
                        value={valueTo}/>
            </div>
          </div>
        )
      }
    </div>
  )
}
CalendarInput.propTypes = {
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  printouts: PropTypes.array,
  valueFrom: PropTypes.string,
  valueTo: PropTypes.string
}

export default CalendarInput
