
import * as yup from "yup"

/**
 * get user validation schema.
 * @param {object} errorMsg {firstname:'',lastname:'',language:'',country:'',phone:'',email:'',password:'', confirmpassword:'', privacy:''}
 */
const getUserValidationShema = (errorMsg) => {
  return yup.object().shape({
    isUserGroup: yup.boolean(),
    usergroup: yup
      .string()
      .when('isUserGroup', {
        is: true,
        then: yup.string().required(errorMsg.usergroup),
        otherwise: yup.string()
      }),
      // .string()
      // .required(errorMsg.usergroup),
    firstname: yup
      .string()
      .required(errorMsg.firstname),
    lastname: yup
      .string()
      .required(errorMsg.lastname),
    language: yup
      .string()
      .required(errorMsg.language),
    country: yup
      .string()
      .required(errorMsg.country),
    phone: yup
      .string()
      .required(errorMsg.phone)
      .matches(
        /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g, 
        errorMsg.phone
      ),
    email: yup
      .string()
      .email(errorMsg.email)
      .required(errorMsg.email),
    password: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.password
      ),
      confirmpassword: yup
      .string()
      .required(errorMsg.confirmpassword)
      .when("password", {
        is: password => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], errorMsg.confirmpassword)
      }),
      privacy: yup
      .string()
      .required(errorMsg.privacy)
  })
}

/**
 * get user validation schema.
 * @param {object} errorMsg {email:''}
 */
 const getEmailValidationShema = (errorMsg) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(errorMsg.email)
      .required(errorMsg.email)
  })
}

/**
 * get password validation schema for password reset.
 * @param {object} errorMsg {password:'', confirmpassword:''}
 */
 const getPasswordResetValidationShema = (errorMsg) => {
  return yup.object().shape({
    password: yup
      .string()
      .required(errorMsg.password)
      .matches(
        // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        /^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        errorMsg.password
      ),
      confirmpassword: yup
      .string()
      .required(errorMsg.confirmpassword)
      .when("password", {
        is: password => (password && password.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref("password")], errorMsg.confirmpassword)
      })
  })
}

/**
 * validates form and returns an object of errors
 * @param {Schema Object} schema 
 * @param {object} formData 
 * @returns object 
 */
const validateForm = async (schema, formData) => {
  try {
    await schema.validate(formData, { abortEarly: false })
    return {}
  } catch(err) {
    const errors = await processErrorMessage(err)
    return errors
  } 
}

/**
 * Process error from ValidationError to a key/value pair
 * @param {ValidationError} e 
 * @returns object
 */
const processErrorMessage = async (e) => {
  let err = {}
  const errors = e.inner
  
  for( let i in errors ) {
    err[errors[i].path] = errors[i].message
  }
  return err
}

export {
  getUserValidationShema,
  getEmailValidationShema,
  getPasswordResetValidationShema,
  validateForm
}