export function createCSV(data) {
    var lineDelimiter = '\n';
    var csv = {
        'title': '',
        'data': ''
    };

    csv.title = data.title;
    csv.data = data.data.map((it) => it.join(",")).join(lineDelimiter);
    return csv;
}

export function downloadCSV(csv) {
    var csvContent = csv.data;

    var blob = new Blob([csvContent], {type: "text/csv"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = csv.title;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
