import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from "../../../libs/contextLib";

const PublicRoute = ({component: Component, ...rest}) => {
    const {isAuthenticated } = useAppContext()

    return (
        <Route 
            {...rest}
            render={ props => (
                (!isAuthenticated || isAuthenticated === undefined || isAuthenticated === null)
                ? <Component {...props} />
                : <Redirect 
                    to={{
                        pathname: "/",
                        state: {from: props.location} 
                    }} 
                />
            )}
        />
    )
}

export default PublicRoute