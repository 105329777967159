import React from 'react'
import {Route, Switch} from 'react-router-dom'
import PageNotFound from '../../PageNotFound'
import Home from '../../Dashboard'
import PublicRoute from "../PublicRoute"
import CreateAccount from "../../CreateAccount"
import ForgotPassword from "../../ForgotPassword"
import Login from "../../Login"

function Routes(props) {
  return (
    <Switch>
      <PublicRoute exact path='/login' component={Login} />
      <PublicRoute exact path='/register/create-account' component={CreateAccount} />
      <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
      <Route component={Home}/>
      <Route component={PageNotFound}/>
    </Switch>
  )
}


export default Routes
