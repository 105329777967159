import React, {useEffect, useState} from 'react'
import './dashboard.scss'
import {Lens, CaretDownIcon} from './../../icons/index'
import {useTranslation} from 'react-i18next'
import {_baseGetFetch, _basePostFetch, _baseDeleteFetch} from "../../utils/baseFetch";
import {useWindowSize} from "../../libs/hooks";
import {CloseCircleIcon} from "../../icons";
import ClickOutside from "../common/ClickOutside";
import {uniqBy} from 'lodash-es'
import CalendarInput from "../common/CalendarInput";
import {formatDate, formatTime, formatCurrentTime} from "../../utils/filters/date";
import {createCSV, downloadCSV} from "../../utils/generateCsv";
import {toast} from "react-toastify";


const Dashboard = () => {

  const {t} = useTranslation()
  const [formData, updateFormData] = useState({})
  const [calendarData, updateCalendarData] = useState({})
  const [congratulationsModal, toggleModal] = useState(false)
  const [codes, setCodes] = useState([{
    code: 'code'
  }])
  const [checkedIds, setCheckedIds] = useState([])
  const [dataFraction, setDataFraction] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [csvModal, setCsvModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(t('tabs.new-promo'))
  const tabs = [t('tabs.new-promo'), t('tabs.archive-promo'), t('tabs.heater-activation')]
  const [isLoading, setIsLoading] = useState(false)
  const [generatedBySn, setGeneratedBySn] = useState(null)
  const [inputSerialNumber, setInputSerialNumber] = useState(null)
  const isMobile = useWindowSize()[0] <= 768
  const [selected, setSelected] = useState({
    name: t('placeholders.select-product')
  })
  const [selectedFunction, setSelectedFunction] = useState({
    name: t('placeholders.select-function')
  })
  const [selectedDuration, setSelectedDuration] = useState({
    id: null,
    name: t('placeholders.select')
  })
  const [options, setOptions] = useState([])
  const [functionsOptions, setFunctionsOptions] = useState([])
  const [duration] = useState([
    {
      id: 0,
      name: t('placeholders.unlimited')
    },
    {
      id: 1,
      name: t('placeholders.range')
    }
  ])

  const [errorsMsg, setErrors] = useState([])
  const [queryParams] = useState({
    order_by: 'id',
    order_desc: 'order_desc',
    search: null,
    per_page: 15,
    page: 1
  })

  const getData = (existingData = []) => {
    setIsLoading(true)
    _baseGetFetch(`/codes`, queryParams)
      .then(data => {
        setDataFraction(uniqBy([...existingData, ...data.data], 'id'))
        setHasMore(data.data && data.data.length)
        setIsLoading(false)
      })
  }

  const getFunctions = (deviceId) => {
    setIsLoading(true)
    _baseGetFetch(`/devices/${deviceId}/functions`)
      .then(data => {
        setFunctionsOptions(data.data)
        if (data.data && data.data.length === 1) {
          setSelectedFunction(data.data[0])
        }
        setIsLoading(false)
      })
  }

  const toggleCsvModal = () => {
    const val = !csvModal
    if (val) {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    } else {
      window.onscroll = () => {
      };
    }
    updateFormData({})
    setCsvModal(val)
  }

  const exportCSV = () => {
    let before = calendarData.created_before
    let after = calendarData.created_after

    if (calendarData.created_before === calendarData.created_after) {
      after = `${formatDate(calendarData.created_before)} 00:00:00`
      before = `${formatDate(calendarData.created_after)} 23:59:59`
    }


    if (calendarData.created_before && calendarData.created_after) {
      setIsLoading(true)
      _baseGetFetch(`/codes`, {
        created_before: before,
        created_after: after,
      })
        .then(data => {
          handleCsvData(data.data)
          setIsLoading(false)
        })
    } else {
      toast.error(t('error.date'))
      setIsLoading(false)
    }
  }

  const handleCsvData = (data) => {
    if (data) {
      const headers = [
        t('archive.id'),
        t('archive.product'),
        t('archive.description'),
        t('archive.code'),
        t('archive.discount'),
        t('archive.redeemed'),
        t('archive.redeemed-date'),
        t('archive.enabled'),
        t('archive.enabled-date'),
        t('archive.start-time'),
        t('archive.end-time'),
        t('archive.email'),
        t('archive.creation-date'),
        t('archive.notes')
      ]

      let body = data.map(option => {
        return [
          option.id,
          option.function_name,
          option.description,
          option.code,
          `${option.discount}%`,
          option.redeemed_at ? t('yes') : t('no'),
          option.redeemed_at,
          option.enabled_at ? t('yes') : t('no'),
          option.enabled_at,
          option.valid_from,
          option.valid_until,
          option.email,
          option.created_at,
          option.notes
        ]
      })

      const csv = createCSV({
        title: `${t('promo_archives')}.csv`,
        data: [headers, ...body.reverse()]
      })

      downloadCSV(csv)
    }
  }

  const disableScrolling = () => {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () {
      window.scrollTo(x, y);
    };
    toggleModal(true)
  }

  const enableScrolling = () => {
    window.onscroll = function () {
    };
    toggleModal(false)
    changeTabAndFetch()
  }

  const checkedCodes = (e) => {
    const value = checkedIds.find(it => it === Number(e.target.value))
    if (value !== Number(e.target.value)) {
      setCheckedIds([...checkedIds, Number(e.target.value)])
    } else {
      const index = checkedIds.indexOf(Number(e.target.value))
      checkedIds.splice(index, 1)
      setCheckedIds([...checkedIds])
    }
  }


  const checkboxAll = (e) => {
    if (checkedIds.length === 0 || checkedIds.length !== dataFraction.length) {
      if (e.target.value === "0") {
        const ids = dataFraction.map(it => it.id)
        setCheckedIds(ids)
        toast.success(`${dataFraction.length} ${t('number-selected')}`)
        toast.clearWaitingQueue();
      }
    } else {
      setCheckedIds([])
    }
  }

  const updateCode = (enabled = true) => {
    const data = {
      "code_ids": checkedIds,
      "enabled": enabled
    }

    if (checkedIds.length !== 0) {
      setIsLoading(true)
      _basePostFetch(`/codes/enable`, data)
        .then(data => {
          setDataFraction([])
          queryParams.page = 1
          getData()
          setCheckedIds([])
          if (enabled === true) {
            toast.success(t('error.enabled'))
            toast.clearWaitingQueue();
            setIsLoading(false)
          } else {
            toast.error(t('error.disabled'))
            toast.clearWaitingQueue();
            setIsLoading(false)
          }
        })
        .catch(error => {
          setErrors(error.errors)
          toast.error(t('error.wrong'))
          toast.clearWaitingQueue();
          setIsLoading(false)
        })
    }

  }

  const deleteCode = () => {
    if (checkedIds.length !== 0) {
      const confirmation = window.confirm(t('buttons.delete-confirm'))
      if (confirmation === true) {
        const data = {
          "code_ids": checkedIds
        }
        setIsLoading(true)
        _baseDeleteFetch(`/codes`, data)
          .then(data => {
            setDataFraction([])
            setCheckedIds([])
            getData()
            toast.error(t('error.deleted'))
            toast.clearWaitingQueue();
            setIsLoading(false)
          })
          .catch(error => {
            setErrors(error.errors)
            toast.error(t('error.wrong'))
            toast.clearWaitingQueue();
            setIsLoading(false)
          })
      }
    }
  }

  function displayError(param) {
    if (errorsMsg.length !== 0) {
      const error = errorsMsg.find(it => it.param === param)
      if (error) {
        return error.msg
      }
    }
  }

  const formatVal = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const handleFrom = (e) => {
    const value = `${e} 00:00:00`
    updateFormData({
      ...formData,
      valid_from: value
    })
    updateCalendarData({
      ...calendarData,
      created_after: value
    })
  }

  const handleTo = (value) => {
    const valueUntil = `${value} 23:59:59`
    const valueFrom = `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth() + 1)}-${formatVal((new Date()).getDate())} 00:00:00`

    const data = {
      ...formData,
      valid_until: valueUntil
    }

    if (!formData.valid_from) {
      data.valid_from = valueFrom
    }
    updateFormData(data)
    updateCalendarData({
      ...calendarData,
      created_before: valueUntil
    })
  }

  const dateValueFrom = () => {
    return formData.valid_from ? formData.valid_from.split(' ')[0] : null
  }

  const dateValueUntil = () => {
    return formData.valid_until ? formData.valid_until.split(' ')[0] : null
  }

  const handleChange = (e, enabled = null) => {
    setErrors([])
    setGeneratedBySn(null)
    if (e.target.name === 'serial_number') {
      return setInputSerialNumber(e.target.value.trim())
    }

    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim()
    });

    if (enabled) {
      updateFormData({
        ...formData,

        [e.target.name]: enabled
      });
    }
  }

  const isUnlimited = (param) => {
    if (param.name === "Unlimited") {
      const now = new Date()
      updateFormData({
        ...formData,
        valid_from: null,
        valid_until: null
      })
    }
  }

  const insertPromo = () => {
    const data = {
      ...formData,
      function_id: selectedFunction.id,
    }

    setIsLoading(true)
    _basePostFetch(`/codes`, data)
      .then(data => {
        setCodes(data.data)
        updateFormData({})
        disableScrolling()
        setErrors([])
        setIsLoading(false)
      })
      .catch(error => {
        if (error.response && error.response.status && error.response.status !== 200) {
          const errors = error.response.data.errors
          setErrors(errors)
          setIsLoading(false)
        }
        toast.error(t('error.all-fields'))
        toast.clearWaitingQueue();
      })
  }

  const changeTabAndFetch = (tab) => {
    if (tab === tabs[1]) {
      queryParams.page = 1
      getData()
    }
    setSelectedTab(tab)
  }

  const loadMore = () => {
    queryParams.page += 1
    getData(dataFraction)
  }

  const handleSearch = (e) => {
    queryParams.page = 1
    if (e.target.value === '') {
      queryParams.search = null
    } else {
      queryParams.search = e.target.value.trim()
    }
    setDataFraction([])
    setTimeout(() => {
      getData()
    }, 500)
  }

  const handleClick = (option, toggleVisibility) => {
    toggleVisibility(false)
    getFunctions(option.id)
    setSelected(option)
  }

  const handleFunctionClick = (option, toggleVisibility) => {
    toggleVisibility(false)
    getFunctions(option.id)
    setSelectedFunction(option)
  }

  const handleDurationClick = (option, toggleVisibility) => {
    toggleVisibility(false)
    setSelectedDuration(option)
    isUnlimited(option)
  }

  const selectInput = () => (
    <div className="select-input cursor-pointer w-100">
      <div className="height d-flex flex-align-center flex-justify-between">
        <div>{selected && selected.name}</div>
        <CaretDownIcon className="mr-5 caret-dropdown-icon"/>
      </div>
    </div>
  )

  const selectOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {options.map((option) => (
        <div className="option cursor-pointer no-wrap" key={option.id}
             onClick={(e) => handleClick(option, toggleVisibility)}>{option.name}</div>
      ))
      }
    </div>
  )

  const selectFunctionInput = () => (
    <div className="select-input cursor-pointer w-100">
      <div className="height d-flex flex-align-center flex-justify-between">
        <div>{selectedFunction && selectedFunction.name}</div>
        <CaretDownIcon className="mr-5 caret-dropdown-icon"/>
      </div>
    </div>
  )

  const selectFunctionOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {functionsOptions.map((option) => (
        <div className="option cursor-pointer no-wrap" key={option.id}
             onClick={(e) => handleFunctionClick(option, toggleVisibility)}>{option.name}</div>
      ))
      }
    </div>
  )

  const selectDurationInput = () => (
    <div className="select-input cursor-pointer w-100">
      <div className="height d-flex flex-align-center flex-justify-between">
        <div>{selectedDuration && selectedDuration.name}</div>
        <CaretDownIcon className="mr-5 caret-dropdown-icon"/>
      </div>
    </div>
  )

  const selectDuration = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {duration.map((option) => (
        <div className="option cursor-pointer no-wrap" key={option.id}
             onClick={(e) => handleDurationClick(option, toggleVisibility)}>{option.name}</div>
      ))
      }
    </div>
  )

  const generateSerialNumberCode = () => {
    setIsLoading(true)
    const data ={
      serial_number: inputSerialNumber
    }
    if (inputSerialNumber) {
      _basePostFetch(`/codes/activation-code`, data)
        .then(data => {
          setGeneratedBySn(data.data)
          toast.success(t('code-generated'))
          toast.clearWaitingQueue()
        })
        .catch(error => {
          if (error.response && error.response.status && error.response.status !== 200) {
            const errors = error.response.data.errors
            setErrors(errors)
          }
          toast.error(t('error.wrong'))
          toast.clearWaitingQueue()
        })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (selectedTab === tabs[0] || selectedTab === tabs[1]) {
      setIsLoading(true)
      _baseGetFetch(`/devices`)
        .then(data => {
          setOptions(data.data)
          if (data.data && data.data.length === 1) {
            setSelected(data.data[0])
            getFunctions(data.data[0].id)
            setIsLoading(false)
          }
        })
    }
  }, [selectedTab])

  return (
    <div className="dashboard">
      {isLoading ? <div className="lds-dual-ring"></div> : ''
      }
      {
        congratulationsModal && (
          <div className="congratulations-modal d-flex flex-column flex-align-center">
            <div className="modal-overlay" onClick={enableScrolling}/>
            <div className="modal">
              <CloseCircleIcon className="close-button" onClick={enableScrolling}/>
              <div className="promo-code-list center">{t('modal.title')}</div>
              <div className="code-list">
                {
                  codes && codes.map(code => (
                    <div key={code.toString()}>{code}</div>
                  ))
                }
              </div>
              <div className="promo-code-total d-flex flex-align-center">
                <p>{t('modal.generated')}</p>
                <span>{codes.length}</span>
              </div>
            </div>
          </div>
        )
      }
      {
        csvModal && (
          <div className="congratulations-modal d-flex flex-column flex-align-center">
            <div className="modal-overlay" onClick={toggleCsvModal}/>
            <div className="csv-modal">
              <CloseCircleIcon className="close-button" onClick={toggleCsvModal}/>
              <div className="title center">{t('csv-modal.title')}</div>
              <CalendarInput
                onChangeFrom={(value) => handleFrom(value)}
                onChangeTo={(value) => handleTo(value)}
              />
              <div className="download-csv d-flex flex-align-center">
                <button onClick={exportCSV} disabled={isLoading}>{t('buttons.download')}</button>
              </div>
            </div>
          </div>
        )
      }
      <div className="container d-flex flex-justify-center flex-align-center flex-column">
        <div className="container-title d-flex flex-align-center">
          <h1 className="title">
            {t('title')}
          </h1>
        </div>

        <div className="tabs d-flex flex-row flex-justify-between">
          <div className="left d-flex flex-row">
            {
              tabs && tabs.length && tabs.map((tab, index) => (
                tab === selectedTab ?
                  <div
                    className={`tab-${tab === selectedTab ? 'active' : 'inactive'} d-flex flex-justify-center flex-align-center`}
                    onClick={() => setSelectedTab(tab)} key={index}>
                    {tab}
                  </div>
                  :
                  <div
                    className={`tab-${tab === selectedTab ? 'active' : 'inactive'} d-flex flex-justify-center flex-align-center`}
                    onClick={() => changeTabAndFetch(tab)} key={index}>
                    {tab}
                  </div>
              ))
            }
          </div>

          {selectedTab === t('tabs.archive-promo') && !isMobile && (
            <div className="right d-flex flex-align-end flex-row">
              <button className="tabs-button" onClick={toggleCsvModal} disabled={isLoading}>
                {t('buttons.download')}
              </button>
              <button className="tabs-button mr" onClick={() => updateCode(true)} disabled={isLoading}>
                {t('buttons.enable')}
              </button>
              <button className="tabs-button " onClick={() => updateCode(false)} disabled={isLoading}>
                {t('buttons.disable')}
              </button>
              <button className="tabs-button" onClick={() => deleteCode()} disabled={isLoading}>
                {t('buttons.delete')}
              </button>
              <div className="tabs-search d-flex flex-row flex-align-center">
                <input type="text" placeholder={t('search')} onChange={handleSearch}/>
                <Lens className="lens"/>
              </div>
            </div>
          )
          }
        </div>

        <div className="container-body">
          {selectedTab === tabs[0] &&
          <div className="form d-flex flex-column">
            <form action="#">

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="product">
                  {t('product-labels.product')}
                  <span>*:</span>
                </label>
                <ClickOutside itemRef="click-outside-wrapper" eventItem={selectInput()}
                              toDisplayItem={selectOptions}/>
                <div className="relative">
                  <p className="error">{displayError('function_id')}</p>
                </div>
              </div>
              {
                selected && selected.id && (
                  <div
                    className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                    <label htmlFor="functions">
                      {t('product-labels.function')}
                      <span>*:</span>
                    </label>
                    <ClickOutside itemRef="click-outside-wrapper"
                                  eventItem={selectFunctionInput()}
                                  toDisplayItem={selectFunctionOptions}/>
                    <div className="relative">
                      <p className="error">{displayError('function_id')}</p>
                    </div>
                  </div>
                )
              }

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="promo_description">
                  {t('product-labels.description')}
                </label>
                <input type="textarea" placeholder={t('placeholders.description')}
                       name="description" onChange={handleChange}/>
              </div>

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="discount_percentage">
                  {t('product-labels.discount')}
                  <span>*:</span>
                </label>
                <input type="number" placeholder={t('placeholders.discount')} name="discount"
                       onChange={handleChange}/>
                <div className="relative">
                  <p className="error">{displayError('discount')}</p>
                </div>
              </div>

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="generate_promo">
                  {t('product-labels.generate')}
                  <span>*:</span>
                </label>
                <input type="number" name="count" onChange={handleChange}/>
                <div className="relative">
                  <p className="error">{displayError('count')}</p>
                </div>
              </div>

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="duration">
                  {t('product-labels.duration')}
                </label>
                <div id="calendar-input"
                     className="calendar-option d-flex flex-row flex-align-center">
                  {selectedDuration.id === 1 ?
                    <CalendarInput
                      onChangeFrom={(value) => handleFrom(value)}
                      valueFrom={dateValueFrom()}
                      onChangeTo={(value) => handleTo(value)}
                      valueTo={dateValueUntil()}
                    />
                    :
                    <ClickOutside itemRef="click-outside-wrapper"
                                  eventItem={selectDurationInput()}
                                  toDisplayItem={selectDuration}/>
                  }
                </div>
              </div>

              <div className="input-wrapper flex-row flex-justify-end flex-align-center d-flex">
                <label htmlFor="notes">
                  {t('product-labels.notes')}
                </label>
                <input type="textarea" placeholder={t('placeholders.notes')} name="notes"
                       onChange={handleChange}/>
              </div>

              <div className="input-wrapper flex-row flex-justify-start flex-align-center d-flex">
                <label htmlFor="promo_enabled">
                  {t('product-labels.enabled')}
                </label>
                <div className="checkbox-container">
                  <input className="form-checkbox" type="checkbox"
                         placeholder={t('placeholders.enabled')}
                         name="enabled" onChange={(e) => handleChange(e, true)}/>
                </div>
              </div>
            </form>
            <div className="button-wrapper d-flex flex-justify-end">
              <button className="submit-button cursor-pointer" onClick={insertPromo}
                      disabled={isLoading}>
                {t('submit-button')}
              </button>
            </div>
          </div>
          }
          {selectedTab === tabs[1] &&
          <div className="container-archive">
            {!isMobile ?
              <table className="fixed_header">
                <thead>
                <tr>
                  <th>All<input type="checkbox" readOnly onClick={(e) => checkboxAll(e)} value="0"
                                checked={checkedIds.length === dataFraction.length}/></th>
                  <th className="th-id">
                    {t('archive.id')}
                  </th>
                  <th className="th-product">
                    {t('archive.product')}
                  </th>
                  <th className="th-description">
                    {t('archive.description')}
                  </th>
                  <th className="th-code">
                    {t('archive.code')}
                  </th>
                  <th className="th-discount">
                    {t('archive.discount')}
                  </th>
                  <th className="th-redeemed">
                    {t('archive.redeemed')}
                  </th>
                  <th className="th-redeemed-date">
                    {t('archive.redeemed-date')}
                  </th>
                  <th className="th-enabled">
                    {t('archive.enabled')}
                  </th>
                  <th className="th-enabled-date">
                    {t('archive.enabled-date')}
                  </th>
                  <th className="th-start">
                    {t('archive.start-time')}
                  </th>
                  <th className="th-end">
                    {t('archive.end-time')}
                  </th>
                  <th className="th-email">
                    {t('archive.email')}
                  </th>
                  <th className="th-creation">
                    {t('archive.creation-date')}
                  </th>
                  <th className="th-notes">
                    {t('archive.notes')}
                  </th>
                </tr>
                </thead>
                <tbody>
                {dataFraction.map(data => (
                    <tr key={data.id}>
                      <td>
                        <input type="checkbox" onChange={(e) => checkedCodes(e)}
                               value={data.id} checked={checkedIds.includes(data.id)}/>
                      </td>
                      <td key={1} className="batch" onClick={document.execCommand("copy")}>
                        <span>{data.batch}</span></td>
                      <td key={2} className="ellipsis function"
                          title={data.notes}>{data.function_name}</td>
                      <td key={3} className="ellipsis description"
                          title={data.notes}>{data.description}</td>
                      <td key={4}>{data.code}</td>
                      <td key={5}>{data.discount} %</td>
                      <td key={6}>{data.redeemed_at ? <div className="active">{t('yes')}</div> :
                        <div className="inactive">No</div>}</td>
                      <td key={7}>{
                        data.redeemed_at &&
                        `${formatDate(data.redeemed_at, false)}`
                      }
                      </td>
                      <td key={8}>{data.enabled_at ? <div className="active">{t('yes')}</div> :
                        <div className="inactive">{t('no')}</div>}</td>
                      <td key={9}>{
                        data.enabled_at &&
                        `${formatDate(data.enabled_at, false)}`
                      }
                      </td>
                      <td key={10}>
                        {data.valid_from &&
                        `${formatCurrentTime(data.valid_from)} ${formatDate(data.valid_from, false)}`
                        }
                      </td>
                      <td key={11}>
                        {data.valid_until &&
                        `${formatCurrentTime(data.valid_until)} ${formatDate(data.valid_until, false)}`
                        }
                      </td>
                      <td key={12}>{data.email}</td>
                      <td key={13}>{data.created_at &&
                      `${formatDate(data.created_at, false)}`}</td>
                      <td key={14} className="ellipsis notes" title={data.notes}>{data.notes}</td>
                    </tr>
                  )
                )}
                </tbody>


              </table>
              :
              <div>
                <div className="right d-flex flex-align-end flex-row">
                  <button className="tabs-button mr" onClick={(e) => checkboxAll(e)} value="0"
                          disabled={isLoading}>
                    {t('buttons.select-all')}
                  </button>
                  <button className="tabs-button mr" onClick={() => updateCode(true)}
                          disabled={isLoading}>
                    {t('buttons.enable')}
                  </button>
                  <button className="tabs-button " onClick={() => updateCode(false)}
                          disabled={isLoading}>
                    {t('buttons.disable')}
                  </button>
                  <button className="tabs-button ml" onClick={() => deleteCode()}
                          disabled={isLoading}>
                    {t('buttons.delete')}
                  </button>
                  <div className="tabs-search d-flex flex-row flex-align-center">
                    <input type="text" placeholder={t('search')} onChange={handleSearch}/>
                    <Lens className="lens"/>
                  </div>
                </div>

                {dataFraction.map(data => (
                  <div className="card d-flex flex-justify-center" key={data.id}>
                    <div className="promo-data d-flex flex-column">
                      <div className="d-flex flex-column">
                        <div className="table-checkbox">
                          <input type="checkbox" onChange={(e) => checkedCodes(e)}
                                 value={data.id} checked={checkedIds.includes(data.id)}/>
                        </div>
                        <div>
                          {t('archive.id')}
                        </div>
                        <div>
                          {t('archive.product')}
                        </div>
                        <div>
                          {t('archive.description')}
                        </div>
                        <div>
                          {t('archive.code')}
                        </div>
                        <div>
                          {t('archive.discount')}
                        </div>
                        <div>
                          {t('archive.redeemed')}
                        </div>
                        <div>
                          {t('archive.redeemed-date')}
                        </div>
                        <div>
                          {t('archive.enabled')}
                        </div>
                        <div>
                          {t('archive.enabled-date')}
                        </div>
                        <div>
                          {t('archive.start-time')}
                        </div>
                        <div>
                          {t('archive.end-time')}
                        </div>
                        <div>
                          {t('archive.email')}
                        </div>
                        <div>
                          {t('archive.creation-date')}
                        </div>
                        <div>
                          {t('archive.notes')}
                        </div>
                      </div>
                    </div>
                    <div className="promo-data d-flex flex-column">
                      <div className="d-flex flex-column flex-justify-start">
                        <div>&nbsp;</div>
                        <div className="batch" key={1}>{data.batch}</div>
                        <div key={2}>{data.function_name} &nbsp;</div>
                        <div key={3}>{data.description} &nbsp;</div>
                        <div key={4}>{data.code} &nbsp;</div>
                        <div key={5}>{data.discount} % &nbsp;</div>
                        <div className="toggle" key={6}>{data.redeemed_at ?
                          <p className="active">{t('yes')}</p> :
                          <p className="inactive">{t('no')}</p>
                        }
                        </div>
                        <div key={7}>{
                          data.redeemed_at &&
                          `${formatDate(data.redeemed_at, false)}`
                        } &nbsp;</div>
                        <div className="toggle" key={8}>{data.enabled_at ?
                          <p className="active">{t('yes')}</p> :
                          <p className="inactive">{t('no')}</p>}

                        </div>
                        <div key={9}>{
                          data.enabled_at &&
                          `${formatDate(data.enabled_at, false)}`
                        } &nbsp;</div>
                        <div key={10}>{data.valid_from &&
                        `${formatCurrentTime(data.valid_from)} ${formatDate(data.valid_from, false)}`
                        } &nbsp;</div>
                        <div key={11}>{data.valid_until &&
                        `${formatCurrentTime(data.valid_until)} ${formatDate(data.valid_until, false)}`
                        } &nbsp;</div>
                        <div key={12}>{data.email} &nbsp;</div>
                        <div key={13}>{data.created_at &&
                        `${formatDate(data.created_at, false)}`} &nbsp;</div>
                        <div key={14}>{data.notes} &nbsp;</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
          }
          {selectedTab === tabs[2] &&
          <div className="container-heater">
            <div className="label">
              {t('generate-code')}
            </div>
            <div className="input-wrapper d-flex">
              <input type="text" name="serial_number" placeholder={t('enter-serial')} value={inputSerialNumber || ''}
                     onChange={handleChange}/>
              <div className="relative">
                <p className="error generate">{displayError('serial_number')}</p>
              </div>
              <button className="submit-button" onClick={generateSerialNumberCode}>{t('labels.submit')}</button>
            </div>
            {generatedBySn &&
            <div className="generated-code d-flex flex-align-center">
              <div>{t('code-for') + ' ' + inputSerialNumber}:</div>
              <h1>{generatedBySn}</h1>
            </div>
            }
          </div>
          }
        </div>
        {selectedTab === tabs[1] ?
          (hasMore ?
            <button className="button load-more" onClick={() => loadMore()} disabled={isLoading}>
              {t('buttons.load-more')}
            </button> :
            <div>
              {t('buttons.all-loaded')}
            </div>) : ''
        }
      </div>
    </div>
  )
}

export default Dashboard
