import React from 'react'
import "./text-input-field.scss"

 const TextInputField = ({inputIcon, type, name, placeholder, value='', isRequired=false, error='', isSubmitting=false, sublabel='', rightIcon=null, ...props}) => {
    return (
        <div className={"input-col-wrapper"  + (inputIcon ? ' has-icon ' : '')}>
            <div className={"input-box " + (error && isSubmitting ? 'input-error' : '')}> 
                {inputIcon !== null && inputIcon}
                <input
                    type={type}
                    name={name}
                    value={value}
                    {...props}
                    className={error && isSubmitting ? 'input-error' : ''}
                />
                <div className="placeholder">
                    {placeholder}{isRequired && <span>*</span>}
                </div>
                { rightIcon !== null && rightIcon }
            </div>
            {(sublabel) && <div className="sub-label">{sublabel}</div>}
            {(error && isSubmitting) && <div className="error-message">{error}</div>}
        </div>
    )
}

export default TextInputField