import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import './banner.scss'
import Language from '../Language'
import {useWindowSize} from "../../../libs/hooks"
import {useAppContext} from "../../../libs/contextLib"
import {FaBars} from 'react-icons/fa'
import Profile from '../../Profile'

import {ColteneLogoWhite,} from '../../../images'
import {
    ExpandIcon,
    ContractIcon,
    CaretDownIcon
} from '../../../icons'
import useOnClickOutside from "./useOnClickOutside"

const Banner = (props) => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const {isAuthenticated} = useAppContext()
    const [width] = useWindowSize()
    const [hideSideBar, setHideSideBar] = React.useState(true)

    const fi = props.authedUser?.firstname.charAt(0)
    const li = props.authedUser?.lastname.charAt(0)

    const ref = React.useRef(null)

    const handleSideBar = () => {
        setHideSideBar((prevState) => !prevState)
    }

    const handleClickOutside = (e) => {
        setHideSideBar((prevState) => !prevState)
    };

    const handleClickInside = (e) => {
        if (e.target.classList.contains("logout-event")) {
          setHideSideBar(true)
          return
        }
        setHideSideBar(false)
      }

    const closeProfileBar = () => {
        //setHideSideBar(true)
    }

    useOnClickOutside(ref, handleClickOutside)

    const handleToggleMenu = (e) => {
        props.toggleNavDisplay()
    }

    const closeSubMenus = () => {
        document.querySelectorAll('.nav-product-wrapper').forEach(function (elem) {
            removeClass(elem, 'current')
        })
    }

    const removeClass = (elem, cls) => {
        const classes = elem.className.split(" ")
        const i = classes.indexOf(cls)

        if (i >= 0) {
            classes.splice(i, 1)
            elem.className = classes.join(" ")
        }
    }

    const makeFullScreen = (e) => {
        const doc = document.documentElement

        if (doc.requestFullscreen) {
            doc.requestFullscreen();
        } else if (doc.webkitRequestFullscreen) {
            doc.webkitRequestFullscreen();
        } else if (doc.msRequestFullscreen) {
            doc.msRequestFullscreen();
        }

        setIsFullScreen(true)
    }

    const exitFullScreen = (e) => {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        }

        setIsFullScreen(false)
    }

    return (
        <>
            <header className={!isAuthenticated ? 'banner u-header' : 'banner'}>
                {!isAuthenticated ? (
                    <div className="u-banner">
                        <div className="banner-r">
                            <div className="banner-language-wrapper">
                                <Language/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="a-banner">
                        <div className="banner-l">
                            <nav className="top-nav">
                                <div className="toggle-nav-menu" onClick={handleToggleMenu}>
                                    <FaBars className="icon"/>
                                </div>
                                <NavLink to='/' activeClassName='active'>
                                    <img
                                        src={ColteneLogoWhite}
                                        alt="Coltene Logo"
                                        className="company-logo"
                                    />
                                </NavLink>
                            </nav>
                            {/* <div className="banner-search-container">
                            <Search
                                placeholder={t("search.what_searching_for")}
                                onSearch={handleSearch}
                            />
                        </div> */}

                        </div>
                        <div className="banner-r">
                            <div className="banner-language-wrapper">
                                <Language/>
                            </div>
                            <div className="banner-expand-wrapper">
                                {
                                    isFullScreen
                                        ? <ContractIcon className="icon" onClick={exitFullScreen}/>
                                        : <ExpandIcon className="icon" onClick={makeFullScreen}/>
                                }

                            </div>
                            {/* <div className="banner-contact-wrapper">
                            <PhoneIcon className="icon" />
                        </div>
                        <div className="banner-notification-wrapper">
                            <NotificationIcon className="icon" />
                        </div> */}
                            <div
                                className="banner-profile-wrapper profile-element"
                                onClick={handleSideBar}
                            >
                                <div className="avatar-title profile-element">
                                    <div className="avatar-initials-small profile-element">
                                        <span className="profile-element">{`${fi}${li}`.trim()}</span>
                                    </div>
                                    
                                </div>
                                <div className="avatar-right profile-element">
                                    <span className="profile-name profile-element">
                                        {props.authedUser?.firstname} {props.authedUser?.lastname}
                                    </span>
                                </div>
                                <div  className="icon profile-element"><CaretDownIcon className="icon profile-element"/></div>
                            </div>
                        </div>
                    </div>
                )}
            </header>
            {!hideSideBar && (
                <div ref={ref} onClick={handleClickInside}>
                    <Profile closeProfileBar={closeProfileBar}></Profile>
                </div>
            )}
        </>
    )
}

function mapStateToProps({authedUser}) {
    return {
        authedUser
    }
}

export default connect(mapStateToProps)(Banner)
