import React, { useState, useEffect }from 'react'
import PropTypes from "prop-types";
import { Auth } from "aws-amplify"
import { toast } from 'react-toastify'
import Button from '../common/Button'
import PublicPageHeading from "../common/PublicPageHeading";
import { useTranslation } from 'react-i18next'
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import "./confirmation-code.scss";
import { Redirect } from 'react-router-dom';

const ConfirmationCode = (props) => {
  const { t } = useTranslation()
  const [authError, setAuthError] = useState("")
  const [emailSent, setEmailSent] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [fields, handleFieldChange] = useFormFields({confirmationcode: ''})
  const [username, setUsername] = useState("")

  useEffect(() => {
    setUsername(props.username)
  }, [props.username])

  const validateForm = () => {
    return (fields.confirmationcode.length > 0)
  }

  const handleConfirmSignup = async (e) => {
    e.preventDefault()
    toast.dismiss()

    setLoading(true)
    setAuthError("")

    try {
      await Auth.confirmSignUp(username, fields.confirmationcode)

      setRedirect(true)
    } catch (e) {
      processError(e)
      // setAuthError(e.message)
      setLoading(false)
    }
  }

  const handleResendCode = async () => {
    toast.dismiss()
    try {
      await Auth.resendSignUp(username)
      
      setEmailSent(true)
      setAuthError(false)
    } catch(e) {
      processError(e)
      // setAuthError(e.message)
    }
  }

  if(redirect) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <div className="center confirmation-code-container public-page">
      <div className="center form-container">
        <div className="">
          <PublicPageHeading heading={t("register.confirmation_code")} intro={t("register.email_confirm_signup")} />
          <div className="confirm-code">
            <form onSubmit={ handleConfirmSignup }>
              { authError &&
                <div className="error-message">
                    {authError}
                </div>
              }
              {
                emailSent &&
                <div className="success-message">{t("register.code_sent")}</div>
              }
              <div className="input-fields">
                <div className="input-box"> 
                  <input
                    type="text"
                    name="confirmationcode"
                    value={fields.confirmationcode}
                    autoFocus
                    autoComplete="off"
                    placeholder="123456"
                    onChange = {handleFieldChange}
                    maxLength="6"
                  />
                </div>
              </div>
              <Button
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
                className='large'
              >
                {t("labels.submit")}
              </Button>
              <div className="resend-confirm-code">
                <div>{t("register.didnt_get_code")}</div>
                <div onClick={handleResendCode} className="resend-link">
                  {t('register.resend_code')}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmationCode.propTypes = {
  username: PropTypes.string.isRequired,
}

export default ConfirmationCode