import React, { useState } from 'react'
import "./language.scss"
import { useWindowSize } from '../../../libs/hooks'
import languages from '../../../utils/languages'
import { useTranslation } from "react-i18next";
import {
    CaretDownIcon,
    CaretUpIcon
} from '../../../icons'

const Language = (props) => {
    const [ optionsExpanded, setOptionsExpanded ] = useState(false)
    const { t, i18n } = useTranslation()
    const langCode = languages[i18n.language] ? i18n.language : 'en-CA' //(i18n.language).substring(0,2)
    const [width] = useWindowSize();

    const toggleShowOptions = (e) => {
        const parentElem = getParentElem(e)
        const targetElem = parentElem.querySelector('.language-options')
        const elemVisible = elementDisplay(targetElem)

        if(elemVisible === 'none') {
            targetElem.style.display = 'block'

            if ( width < 760 ) {
                setOptionsExpanded(true)
            }
        } else {
            targetElem.style.display = 'none'
            setOptionsExpanded(false)
        }
    }

    const updatePreferredLanguage = (e) => {
        const value = e.target.getAttribute('data-code');
        e.target.parentNode.style.display = 'none'
        i18n.changeLanguage(value)
    }

    const elementDisplay = (elem) => {
        return elem.currentStyle ? elem.currentStyle.display : getComputedStyle(elem, null).display;
    }

    const getParentElem = (e) => {
        let parentElem = e.currentTarget.parentNode || ''
        const pClass  = (parentElem !== null) ? parentElem.className : ''

        if (parentElem.tagName.toLowerCase() === 'svg' || pClass.indexOf('container') === -1) {
            parentElem = parentElem.parentNode

            if(parentElem.className.indexOf('-col-') === -1) {
                parentElem = parentElem.parentNode
            }
        }

        return parentElem
    }

    return (
        <div className="language-container" >
            <div className="language-wrapper" onClick={toggleShowOptions}>
                <span className="lang-name">{t(`${languages[langCode]}`)}</span> &nbsp;
                { optionsExpanded
                    ?  <CaretUpIcon className="icon" />
                    :  <CaretDownIcon className="icon" />
                }
            </div>
            <div className="language-options">
                {
                    Object.keys(languages).map(
                        k => <div key={k} className="lang-option" onClick={updatePreferredLanguage} data-code={k}>{t(`${languages[k]}`)}</div>
                    )
                }

            </div>
        </div>
    )
}

export default Language
