import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from "aws-amplify"
import { toast } from 'react-toastify';
import './signup.scss'
import Button from '../common/Button'
import ConfirmationCode from './ConfirmationCode'
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { useTranslation } from 'react-i18next'
import PublicPageHeading from "../common/PublicPageHeading"
import TextInputField from '../common/TextInputField'
import SelectField from '../common/SelectField'
import languages from '../../utils/languages'
import countries from "../../utils/countries.json";
import { getUserValidationShema, validateForm } from '../../utils/formValidation'
import {
  PostBoxIcon,
  WWWIcon,
  EmailIcon,
  BuildingIcon,
  PersonIcon,
  PhoneIcon,
  WorldIcon,
  LockIcon,
  FaxIcon,
  CityIcon,
  ProvinceIcon,
  FlagIcon,
  EyeIcon,
  EyeOffIcon
} from '../../icons'

const CreateAccount = ( props ) => {
  const [error, setError] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [newUser, setNewUser] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true)
  const { t } = useTranslation()
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmpassword: "",
    firstname: "",
    lastname: "",
    language: "",
    company: "",
    companywebsite: "",
    phone: "",
    fax: "",
    city: "",
    stateprovince: "",
    postalcode: "",
    usergroup: "",
    isUserGroup: false
  })

  const userErrorMsg = {
    firstname: t("error.firstname"),
    lastname: t("error.lastname"),
    language: t("error.language"),
    country: t("error.country"),
    phone: t("error.phone"),
    email: t("error.email"),
    password: t("error.password"),
    confirmpassword: t("error.confirm_password"),
    privacy: t("error.privacy"),
    usergroup: t("error.user_group")
  }

  const getLanguageOptions = () => {
    const langs = {}
    Object.keys(languages).forEach( (k) => langs[k] = t(`language.${languages[k]}`) )

    return (
      <SelectField
          name="language"
          value={fields.language}
          options={langs}
          selectLbl={t("select_option_label.select_language")}
          onChange={handleFieldChange}
          error= {error.language ? error.language : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          selectIcon={<WorldIcon className="globe-icon" />}
        />
    )
  }

  const getCountryOptions = () => {
    const countryData = {}
    Object.keys(countries).forEach( (index) => {
      return countryData[countries[index]["code"]] = countries[index]["name"]
    })

    return (
      <SelectField
          name="country"
          value={fields.country}
          options={countryData}
          selectLbl={t("select_option_label.select_country")}
          onChange={handleFieldChange}
          error= {error.country ? error.country : null}
          isRequired={true}
          isSubmitting={isSubmitting}
          selectIcon={<FlagIcon className="flag-icon" />}
        />
    )
  }
  
  const handleSignup = async (e) => {
    e.preventDefault()
    setError({})
    setSubmitting(true)

    toast.dismiss()
    
    const userSchema = getUserValidationShema(userErrorMsg)
    const validateData = await validateForm(userSchema, fields)
    
    setError(validateData)
    
    if(Object.keys(validateData).length === 0) {
      setLoading(true)
      
      try {
        const user = await Auth.signUp({
          'username': fields.email, 
          'password': fields.password,
          'attributes': {
            'email': fields.email,
            'given_name': fields.firstname || '',
            'family_name': fields.lastname || '',
            'locale': fields.language || '',
            'website': fields.companywebsite || '',
            'custom:phone_number': fields.phone || '',
            'custom:fax': fields.fax || '',
            'custom:company': fields.company || '',
            'custom:postal_code': fields.postalcode || '',
            'custom:city': fields.city || '',
            'custom:state': fields.stateprovince || '',
            'custom:country': fields.country || '',
            'custom:user_group': fields.usergroup || 'coltene_customer_service',
            'custom:privacy': fields.privacy ? fields.privacy.toString() : ''
          }
        })
        
        setNewUser(user)
        setLoading(false)
        setSubmitting(false)
      } catch (e) {
        processError(e)
        setLoading(false)
        setSubmitting(false)
      }
    }
  }

  const getCreateAccountSubHeading = () => (
    <div>
      { t("register.already_have_account") + " " }
      <Link to="/" className="login-link"> 
        {t("register.login_account")}
      </Link>
    </div>
  )

  const toggleShowHidePassword = (e) => {
    document.getElementById('password').type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }

  const toggleShowHideConfirmPassword = (e) => {
    document.getElementById('confirmpassword').type = hideConfirmPassword ? "text" : "password"
    setHideConfirmPassword(!hideConfirmPassword)
  }

  return (
    newUser !== null
    ?  <ConfirmationCode 
        username={fields.email} 
      />
    :
    <div className="center signup-container public-page">
      <div className="center form-container">
        <div>
          <PublicPageHeading heading={t(`register.account_registration`)} subheading={getCreateAccountSubHeading()} />
          <div className="signup-form">
            <form onSubmit={ handleSignup } autoComplete="off" noValidate>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="firstname"
                    value={fields.firstname}
                    autoFocus
                    placeholder={t("profile.first_name")}
                    onChange = {handleFieldChange}
                    error= {error.firstname ? error.firstname : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PersonIcon className="user-icon" />}
                  />
                  {
                    getLanguageOptions()
                  }
              </div>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="lastname"
                    value={fields.lastname}
                    placeholder={t("profile.last_name")}
                    onChange = {handleFieldChange}
                    error= {error.lastname ? error.lastname : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PersonIcon className="user-icon" />}
                  />
                  <TextInputField
                    type="text"
                    name="company"
                    value={fields.company}
                    placeholder={t("profile.company")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<BuildingIcon className="building-icon" />}
                  />
              </div>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="phone"
                    value={fields.phone}
                    placeholder={t("profile.phone")}
                    onChange = {handleFieldChange}
                    error= {error.phone ? error.phone : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<PhoneIcon className="phone-icon" />}
                  />
                <TextInputField
                    type="text"
                    name="companywebsite"
                    value={fields.companywebsite}
                    placeholder={t("profile.company_website")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<WWWIcon className="icon" />}
                  />
              </div>
              <div className="input-fields">
                <TextInputField
                    type="text"
                    name="fax"
                    value={fields.fax}
                    placeholder={t("profile.fax")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<FaxIcon className="fax-icon" />}
                  />
                  {
                    getCountryOptions()
                  }
              </div>
              <div className="input-fields row">
                <TextInputField
                    type="email"
                    name="email"
                    value={fields.email}
                    placeholder={t("profile.email")}
                    onChange = {handleFieldChange}
                    error= {error.email ? error.email : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<EmailIcon className="at-icon" />}
                  />
                <TextInputField
                    type="text"
                    name="city"
                    value={fields.city}
                    placeholder={t("profile.city")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<CityIcon className="city-icon" />}
                  />
              </div>
              <div className="input-fields row">
                <TextInputField
                    type="password"
                    name="password"
                    id="password"
                    value={fields.password}
                    placeholder={t("profile.password")}
                    autoComplete="new-password"
                    onChange = {handleFieldChange}
                    error= {error.password ? error.password : null}
                    sublabel={t("profile.password_message")}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<LockIcon className="lock-icon" />}
                    rightIcon={ hidePassword
                      ? <EyeIcon className="eye-icon" onClick={toggleShowHidePassword} />
                      : <EyeOffIcon className="eye-icon" onClick={toggleShowHidePassword} />
                    }
                  />
                <TextInputField
                    type="text"
                    name="stateprovince"
                    value={fields.stateprovince}
                    placeholder={t("profile.state_province")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<ProvinceIcon className="at-icon" />}
                  />
              </div>
              <div className="input-fields row">
                <TextInputField
                    type="password"
                    name="confirmpassword"
                    id="confirmpassword"
                    value={fields.confirmpassword}
                    placeholder={t("profile.confirm_password")}
                    onChange = {handleFieldChange}
                    error= {error.confirmpassword ? error.confirmpassword : null}
                    isRequired={true}
                    isSubmitting={isSubmitting}
                    inputIcon={<LockIcon className="lock-icon" />}
                    rightIcon={ hideConfirmPassword
                      ? <EyeIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                      : <EyeOffIcon className="eye-icon" onClick={toggleShowHideConfirmPassword} />
                    }
                  />
                <TextInputField
                    type="text"
                    name="postalcode"
                    value={fields.postalcode}
                    placeholder={t("profile.postal_code")}
                    onChange = {handleFieldChange}
                    isSubmitting={isSubmitting}
                    inputIcon={<PostBoxIcon className="icon" />}
                  />
              </div>

              <div className="privacy-wrapper">
                <div className="privacy">
                  <input
                  type="checkbox"
                  id="privacy-term"
                  name="privacy"
                  className="privacy"
                  onChange = {handleFieldChange}
                  />
                  <label htmlFor="subscribe">
                    {t("register.i_accept_coltene")} <span><a href="https://www.coltene.com/privacy-policy/" target="_blank" rel="noreferrer" className="external-link">{t("register.privacy_statement")}</a></span> &  
                    <span><a href="https://docs.coltene.com/" target="_blank" rel="noreferrer" className="external-link">{t("register.terms_of_use")}</a></span>.
                  </label>
                  <span className="required">*</span>
                </div>
                {error.privacy && <div className="error-message">{error.privacy}</div>}
              </div>
              <Button
                type="submit"
                isLoading={isLoading}
                className='large'
              >
                {t("register.create_account")}
              </Button>
            </form>
            
          </div>
        </div>
      {/* } */}
      </div>
    </div>
  )
}


export default CreateAccount