import React, {useState} from "react";
import Button from "../common/Button"
import {connect}  from 'react-redux'
import "./profile.scss";
import ProfileInformationIcon from "../../icons/profile/profile_information.svg"
import Auth from "@aws-amplify/auth";
import { useFormFields } from "../../libs/hooks";
import { useTranslation } from 'react-i18next'
import TextInputField from '../common/TextInputField'
import { getUserValidationShema, validateForm } from '../../utils/formValidation'
import languages from '../../utils/languages'
import countries from "../../utils/countries.json";
import SelectField from '../common/SelectField'
import {
  ArrowDownIcon,
  CaretUpIcon,
} from "../../icons";

function EditProfile(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = React.useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const [fields, handleFieldChange] = useFormFields({
    firstname: props.authedUser?.firstname,
    lastname: props.authedUser?.lastname,
    phone: props.authedUser?.phone,
    fax: props.authedUser?.fax,
    language: props.authedUser?.language,
    country: props.authedUser?.country,
    city: props.authedUser?.city,
    stateprovince: props.authedUser?.stateprovince,
    postalcode: props.authedUser?.postalcode
  });

  const userErrorMsg = {
    firstname: t("error.firstname"),
    lastname: t("error.lastname"),
    language: t("error.language"),
    country: t("error.country"),
    phone: t("error.phone"),
    email: t("error.email"),
    password: t("error.password"),
    confirmpassword: t("error.confirm_password"),
    privacy: t("error.privacy"),
    usergroup: t("error.user_group")
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    setError({})
    setSubmitting(true)

    const userSchema = getUserValidationShema(userErrorMsg)
    const validateData = await validateForm(userSchema, fields)
    setError(validateData)

    let user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
        'given_name': fields.firstname || '',
        'family_name': fields.lastname || '',
        'custom:phone_number': fields.phone || '',
        'custom:fax': fields.fax || '',
        'locale': fields.language || '',
        'custom:country': fields.country || '',
        'custom:city': fields.city,
        'custom:state': fields.stateprovince || '',
        'custom:postal_code': fields.postalcode || ''
    });
  }

  const getLanguageOptions = () => {
    const langs = {}
    Object.keys(languages).forEach( (k) => langs[k] = t(`language.${languages[k]}`) )

    return (
      <SelectField
          name="language"
          value={fields.language}
          options={langs}
          selectLbl={t("select_option_label.select_language")}
          selectedIndex={fields.language} 
          onChange={handleFieldChange}
          error= {error.language ? error.language : null}
          isRequired={true}
          isSubmitting={isSubmitting}
        />
    )
  }

  const getCountryOptions = () => {
    const countryData = {}
    Object.keys(countries).forEach( (index) => {
      return countryData[countries[index]["code"]] = countries[index]["name"]
    })

    return (
      <SelectField
          name="country"
          value={fields.country}
          options={countryData}
          selectLbl={t("select_option_label.select_country")}
          selectedIndex={fields.country}
          onChange={handleFieldChange}
          error= {error.country ? error.country : null}
          isRequired={true}
          isSubmitting={isSubmitting}
        />
    )
  }

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-details"
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <span className="profile-span"><img src={ProfileInformationIcon} alt="" /></span>
        <div className="name-icon-profile">
          <span className="profile-span">{t("profile.edit_profile")}</span>
          {isOpen ? <CaretUpIcon className="arrow-down-profile" /> : <ArrowDownIcon className="arrow-down-profile" />}
        </div>
      </div>

      {isOpen && (
        <div className="edit-profile-form-container">
          <TextInputField 
          className="profile-fields-one" 
          placeholder={t("profile.first_name")}  
          autoFocus
          type="text"
          value={fields.firstname} 
          onChange={handleFieldChange} 
          name="firstname"
          isSubmitting={isSubmitting}
          error= {error.firstname ? error.firstname : null}
          isRequired={true} />

          <TextInputField
          className="profile-fields" 
          placeholder={t("profile.last_name")}  
          autoFocus
          type="text" 
          value={fields.lastname} 
          onChange={handleFieldChange} 
          name="lastname"
          isSubmitting={isSubmitting}
          error= {error.lastname ? error.lastname : null}
          isRequired={true} />

          <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.phone")}  
          autoFocus
          type="text" 
          value={fields.phone} 
          onChange={handleFieldChange} 
          name="phone"
          isSubmitting={isSubmitting}
          error= {error.phone ? error.phone : null}
          isRequired={true} />

          <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.fax")} 
          autoFocus
          type="text" 
          value={fields.fax} 
          isSubmitting={isSubmitting}
          onChange={handleFieldChange} 
          name="fax" />

          {/* <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.language")}  
          autoFocus
          type="text" 
          value={fields.language} 
          onChange={handleFieldChange} 
          name="language"
          isSubmitting={isSubmitting}
          error= {error.language ? error.language : null}
          isRequired={true} /> */}

          {/* <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.country")}  
          autoFocus
          type="text"
          value={fields.country} 
          onChange={handleFieldChange} 
          name="country"
          isSubmitting={isSubmitting}
          error= {error.country ? error.country : null}
          isRequired={true} /> */}

          {getLanguageOptions()}

          {getCountryOptions()}

          <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.city")} 
          autoFocus
          type="text"
          value={fields.city} 
          isSubmitting={isSubmitting}
          onChange={handleFieldChange} 
          name="city"/>

          <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.state_province")} 
          autoFocus
          type="text" 
          isSubmitting={isSubmitting}
          value={fields.stateprovince} 
          onChange={handleFieldChange} 
          name="stateprovince" />

          <TextInputField 
          className="profile-fields" 
          placeholder={t("profile.postal_code")} 
          autoFocus
          type="text" 
          isSubmitting={isSubmitting}
          value={fields.postalcode} 
          onChange={handleFieldChange} 
          name="postalcode" />

          <Button onClick={handleUpdate} className="small">{t("labels.save")}</Button>
        </div>
      )}
    
    </div>
  );
}
function mapStateToProps({ authedUser }) {
  return {
    authedUser,
  };
}
export default connect(mapStateToProps)(EditProfile)