import React from 'react'
import { Link } from 'react-router-dom'
import  './page-not-found.scss'
import { useTranslation } from 'react-i18next'

const PageNotFound = (props) => {
    const { t } = useTranslation()

    return (
        <div className="page-not-found">
            <h1>{t("page_not_found.title")}</h1>
            <div>
                {(t("page_not_found.return_to_the"))} <Link to='/'>{t("nav.homepage")}</Link>
            </div>
        </div>
    )
}

export default PageNotFound
