import Avatar from "./Avatar"
import Details from "./Details"
import EditProfile from "./EditProfile"
import EditPassword from "./EditPassword"
import OfficeInformation from "./OfficeInformation"
import { connect } from 'react-redux'
import "./profile.scss";
import { useAppContext } from "../../libs/contextLib"
import { handleSetAuthedUser } from '../../actions/authedUser'
import ShutdownIcon from "../../icons/profile/shutdown.svg"
import { useTranslation } from 'react-i18next'
import { Auth } from "aws-amplify"

 const Index = (props) => {

  const { setAuthenticated } = useAppContext()
  const { t } = useTranslation()

   const handleLogout = async (e) => {
        const { dispatch, closeProfileBar } = props

        Auth.signOut()
        .then(() => setAuthenticated(false))
        .then(() => {
          dispatch(handleSetAuthedUser(null))
          closeProfileBar(true)
        })
    }




  return (
    <div className="profile-container">
      <Avatar />
      <Details />
      <EditProfile />
      <EditPassword />
      <OfficeInformation />
      <div className="input-fields logout-event">
        <div className="profile-input-box logout-event" onClick={handleLogout}>
          <span>
            <img src={ShutdownIcon} alt="" className="sht-dwn logout-event" />
          </span>
          <span className="signout-btn logout-event">
            {t("access.signout")}
          </span>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser
  }
}

export default connect(mapStateToProps)(Index)
